.masthead {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 35rem;
  padding: 15rem 0;
  background: linear-gradient(to bottom, rgba(22, 22, 22, 0.3) 0%, rgba(22, 22, 22, 0.7) 75%, #161616 100%),
    url("../../images/bg-masthead.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;

  .profile-photo {
    border-radius: 50%;
    width: 250px;
    height: 250px;
  }

  h1 {
    font-family: "Varela Round";
    font-size: 2.5rem;
    line-height: 2.5rem;
    letter-spacing: 0.8rem;
    background: -webkit-linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }

  h2 {
    max-width: 20rem;
    font-size: 1rem;
  }
}

@media (min-width: 768px) {
  .masthead {
    h1 {
      font-size: 4rem;
      line-height: 4rem;
    }
  }

  .profile-photo {
    width: 200px;
    height: 200px;
  }
}

@media (min-width: 992px) {
  .masthead {
    height: 100vh;
    padding: 0;

    h1 {
      font-size: 6.5rem;
      line-height: 6.5rem;
      letter-spacing: 0.8rem;
    }
    h2 {
      max-width: 30rem;
      font-size: 1.25rem;
    }
  }
}
