.contact-section {
  padding: 5rem 0 0;
  // background: linear-gradient(to bottom, rgba(22, 22, 22, 0.3) 0%, rgba(22, 22, 22, 0.7) 75%, #161616 100%),
  // url("../../images/bg-masthead.jpg");

  .card {
    border: 0;
    border-bottom: 0.25rem solid #64a19d;

    h4 {
      font-size: 0.8rem;
      font-family: 'Varela Round';
      text-transform: uppercase;
      letter-spacing: 0.15rem;
    }

    hr {
      border-color: #64a19d;
      border-width: 0.25rem;
      width: 3rem;
    }
    
  }

  .social {
    margin-top: 5rem;

    a {
      text-align: center;
      height: 3rem;
      width: 3rem;
      line-height: 3rem;
      color: rgba(255, 255, 255, 0.3);
    }

    &:hover {
      color: rgba(255, 255, 255, 0.5);
    }

    &:active {
      color: #fff;
    }
  }
}