.bg-dark {
  background-color: #161616 !important;
}

#mainNav {
  min-height: 56px;
  background-color: #fff;

  .navbar-toggler {
    font-size: 80%;
    padding: 0.75rem;
    color: #64a19d;
    border: 1px solid #64a19d;
  }

  .navbar-toggler:focus {
    outline: none;
  }

  .navbar-brand {
    color: #161616;
    font-weight: 700;
    padding: 0.9rem 0;
  }

  .navbar-nav {
    .nav-item {
      &:active,
      &:focus {
        outline: none;
        background-color: transparent;
      }
      &:hover {
        color: fade(white, 80%);
        outline: none;
        background-color: transparent;
      }
    }
  }
}

@media (min-width: 992px) {
  #mainNav {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;

    .navbar-brand {
      padding: 0.5rem 0;
      color: rgba(255, 255, 255, 0.5);
    }
    .nav-link {
      transition: none;
      padding: 2rem 1.5rem;
      color: rgba(255, 255, 255, 0.5);

      &:hover {
        color: rgba(255, 255, 255, 0.75);
      }

      &:active {
        color: white;
        border-bottom: 1px solid white;
      }
    }
    .navbar-shrink {
      background-color: #fff;
    }
    .navbar-shrink .navbar-brand {
      color: #161616;
    }
    .navbar-shrink .nav-link {
      color: #161616;
      padding: 1.5rem 1.5rem 1.25rem;
      border-bottom: 0.25rem solid transparent;
    }
    .navbar-shrink .nav-link:hover {
      color: #64a19d;
    }
    .navbar-shrink .nav-link:active {
      color: #467370;
    }
    .navbar-shrink .nav-link.active {
      color: #64a19d;
      outline: none;
      border-bottom: 0.25rem solid #64a19d;
    }
  }
}
